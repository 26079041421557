<template>
  <b-table
    class="my-table"
    :data="results"
    :bordered="isBordered"
    :striped="isStriped"
    :narrowed="isNarrowed"
    :hoverable="isHoverable"
    :loading="isLoading"
    :focusable="isFocusable"
    :mobile-cards="hasMobileCards"
  >
    <b-table-column v-slot="props" field="date" label="Дата" sortable centered>
      {{ new Date(props.row.date).toLocaleDateString() }}
    </b-table-column>

    <b-table-column v-slot="props" field="event.Title" label="Событие" sortable>
      <router-link
        v-if="isEventPublished(props.row)"
        :to="`${calendarPath}/${props.row.event.id}`"
      >
        {{ props.row.event.Title }}
      </router-link>
      <template v-else-if="props.row.event && props.row.event.Title">
        {{ props.row.event.Title }}
      </template>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Дисциплина"
      field="sport_discipline.name"
      sortable
    >
      {{ props.row.sport_discipline.name }}
      {{
        props.row.sport_discipline.round
          ? `, ${props.row.sport_discipline.round}`
          : ""
      }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Место"
      field="place"
      centered
      sortable
    >
      {{ props.row.place }}
    </b-table-column>

    <b-table-column v-slot="props" label="Результат" field="result">
      {{ props.row.result }}
    </b-table-column>
  </b-table>
</template>

<script>
import { AppRoute } from "@/common/const/route";

export default {
  name: "AthleteResultsTable",
  props: {
    results: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      calendarPath: AppRoute.CALENDAR,
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: false,
    };
  },
  methods: {
    isEventPublished({ event }) {
      return !!event?.published_at || false;
    },
  },
};
</script>

<style>
.my-table tr td {
  padding: 1.2rem !important;
}
</style>
