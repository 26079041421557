<template>
  <div class="buttons">
    <b-button
      v-if="isEditor"
      type="is-success"
      icon-left="pencil"
      @click="$emit('edit')"
    >
      Редактировать
    </b-button>
    <b-button
      v-if="isEditor"
      type="is-danger"
      icon-left="delete"
      :disabled="isDeleteDisable"
      @click="$emit('remove')"
    >
      Удалить
    </b-button>
    <b-button
      v-if="isShowDownload"
      type="is-info"
      icon-left="download"
      :loading="loading"
      title="Скачать pdf"
      @click="$emit('download')"
    >
      Скачать
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppEditButtons",
  props: {
    isDeleteDisable: {
      type: Boolean,
      default: false,
    },
    isShowDownload: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },
};
</script>
