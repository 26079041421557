<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Фото</p>
      </header>

      <section class="modal-card-body">
        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="ext:jpeg,jpg,webp|size:150"
          name="Фото"
          slim
        >
          <b-field
            class="file is-primary"
            :class="{ 'has-name': !!file, 'is-danger': !valid }"
          >
            <b-upload
              v-model="file"
              class="file-label"
              @input="isChange = true"
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload" />
                <span class="file-label">Выбрать фото</span>
              </span>
              <span v-if="file && valid" class="file-name">
                {{ file.name }}
              </span>
              <span v-if="errors[0]" class="ml-2 has-text-danger is-size-7">{{
                errors[0]
              }}</span>
            </b-upload>
          </b-field>
        </ValidationProvider>

        <b-image
          v-if="imageSrc"
          class="image mt-3"
          ratio="3by2"
          :src="imageSrc"
        />
        <div v-else class="image-stub" />
      </section>

      <footer class="modal-card-foot">
        <div class="buttons mt-2">
          <b-button
            type="is-success"
            icon-left="check"
            :loading="loading"
            :disabled="invalid || !isChange"
            @click="validate().then(handleSubmit)"
          >
            Сохранить
          </b-button>

          <b-button
            type="is-danger"
            :disabled="isDeleteDisabled"
            :loading="loadingDelete"
            icon-left="delete"
            @click="handleDelete"
          >
            Удалить
          </b-button>

          <b-button icon-left="close" @click="$emit('close')">
            Закрыть
          </b-button>
        </div>
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import { Resource } from "@/common/const/common";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";

export default {
  name: "AppImageForm",

  components: { ValidationProvider, ValidationObserver },

  props: {
    image: {
      type: Object,
      default: null,
    },
    fileField: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      loadingDelete: false,
      isChange: false,
      file: null,
      photo: null,
      field: null,
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),

    isDeleteDisabled() {
      return !this.photo;
    },

    imageSrc() {
      if (!this.file) {
        return null;
      }
      if (this.file !== this.photo) {
        return URL.createObjectURL(this.file);
      }

      return this.photo ? process.env.VUE_APP_API_URL + this.photo.url : null;
    },
  },

  created() {
    this.file = this.image;
    this.photo = this.image;
    this.field = `files.${this.fileField}`;
  },

  async destroyed() {
    this.file = null;
    this.photo = null;
  },

  methods: {
    async handleDelete() {
      try {
        this.loadingDelete = true;

        const data = {};
        data[this.fileField] = null;
        const entity = await this.$api[this.resource].put(data, this.entityId);
        if (this.photo) {
          try {
            await this.$api[Resource.UPLOAD].delete(this.photo.id);
          } catch (e) {
            // console.log(e);
          }
        }
        this.$emit("close", entity);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingDelete = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;
        const formData = new FormData();

        if (this.file !== this.avatar && this.file instanceof Blob) {
          formData.append(this.field, this.file, this.file.name);
        }
        formData.append("data", "{}");

        const data = await this.$api[this.resource].put(
          formData,
          this.entityId
        );
        this.$emit("close", data);
      } catch (e) {
        console.log(e);
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
