<template>
  <div class="columns is-mobile">
    <div class="column" :class="firstRowClass">
      {{ title }}
    </div>
    <div class="column has-text-weight-semibold">
      <template v-if="isLink">
        <a :href="value" target="_blank">{{ value }}</a>
      </template>
      <template v-else>
        {{ value }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTableRow",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    firstRowClass() {
      return this.isFullPage ? "is-1-desktop" : "is-4-desktop";
    },
  },
};
</script>
