<template>
  <span style="margin-right: 1rem">
    <b-button
      v-if="isPublished"
      :disabled="isUnpublishedDisabled"
      icon-right="lock-open-variant"
      type="is-success"
      @click="$emit('unpublished')"
    />

    <!--    <b-icon v-if="isPublished" icon="lock-open-variant" type="is-success" />-->

    <b-tooltip v-else label="Разблокировать">
      <b-button icon-right="lock" type="is-warning" @click="$emit('publish')" />
    </b-tooltip>
  </span>
</template>
<script>
export default {
  name: "AppPublishButton",
  props: {
    isPublished: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isUnpublishedDisabled: true,
    };
  },
};
</script>
