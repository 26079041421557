import { DATE_FORMAT } from "../const/common";
import {
  PUBLISH_ERROR,
  PUBLISH_SUCCESS,
  UNPUBLISHED_ERROR,
  UNPUBLISHED_SUCCESS,
} from "../const/message";

export default {
  methods: {
    async $publish(id, resource) {
      try {
        const data = await this.$api[resource].put(
          {
            published_at: this.$dayjs().format(DATE_FORMAT),
          },
          id
        );
        this.$notifier.success(PUBLISH_SUCCESS);
        return data;
      } catch (e) {
        this.$notifier.error(PUBLISH_ERROR);
      }
    },
    async $unpublished(id, resource) {
      try {
        const data = await this.$api[resource].put(
          {
            published_at: null,
          },
          id
        );
        this.$notifier.success(UNPUBLISHED_SUCCESS);
        return data;
      } catch (e) {
        this.$notifier.error(UNPUBLISHED_ERROR);
      }
    },
  },
};
