<template>
  <div class="container">
    <h1 class="title">
      <PublishButton
        :is-published="isPublished"
        @publish="publish"
        @unpublished="unpublished"
      />
      {{ athlete.fullName }}
    </h1>

    <EditButtons
      :is-delete-disable="isDeleteDisabled"
      :is-show-download="true"
      :loading="loading"
      @edit="handleEdit"
      @remove="handleRemove"
      @download="handleDownloadAthlete"
    />

    <div class="columns is-vcentered">
      <div class="column is-one-third-desktop" style="position: relative">
        <div v-if="athlete.photo" style="max-width: 400px">
          <b-image class="image" ratio="3by2" :src="getImgUrl(athlete.photo)" />
        </div>

        <div v-else class="image-stub" />

        <div v-if="isEditor" style="position: absolute; top: 1rem; left: 1rem">
          <b-button
            type="is-info"
            icon-right="pencil"
            @click="handleEditPhoto"
          />
        </div>
      </div>

      <div class="column">
        <TableRow
          :value="athlete.category_sport ? athlete.category_sport.Title : ''"
          title="Вид спорта"
          class="mb-0"
        />
        <TableRow
          title="Пол"
          :value="athlete.gender ? athlete.gender : '-'"
          class="mb-0"
        />
        <TableRow
          :value="athlete.rank ? athlete.rank.name : ''"
          title="Звание"
          class="mb-0"
        />
        <TableRow
          :value="athlete.birthDate | date"
          title="Дата рождения"
          class="mb-0"
        />

        <TableRow
          :value="athlete.region_ru ? athlete.region_ru.name : ''"
          title="Регион основной"
          class="mb-0"
        />

        <TableRow
          :value="athlete.region_ru_2 ? athlete.region_ru_2.name : ''"
          title="Регион параллельный"
          class="mb-0"
        />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <TableRow
          :value="athlete.hobby ? athlete.hobby : '-'"
          title="Хобби"
          class="mb-0"
          :is-full-page="true"
        />

        <TableRow
          :value="athlete.instagram ? athlete.instagram : '-'"
          title="instagram"
          :is-full-page="true"
        />
      </div>
    </div>

    <div class="block">
      <v-md-preview :text="athlete.description || ''" />
    </div>

    <div v-if="results" class="block">
      <h2 class="subtitle is-uppercase has-text-weight-semibold">Результаты</h2>
      <AthleteResultsTable :results="results" />
    </div>

    <!--    <div>-->
    <!--      <b-carousel v-if="isImages" :indicator-inside="false" :autoplay="false">-->
    <!--        <b-carousel-item v-for="item in athlete.images" :key="item.url">-->
    <!--          <b-image class="image" :src="getImgUrl(item)"></b-image>-->
    <!--        </b-carousel-item>-->
    <!--        <template #indicators="props">-->
    <!--          <b-image-->
    <!--            class="al image is-small is-hidden-mobile"-->
    <!--            :src="getThumbUrl(athlete.images[props.i])"-->
    <!--          ></b-image>-->
    <!--        </template>-->
    <!--      </b-carousel>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import breadcrumbs from "@/common/mixins/breadcrumbs";
import publish from "@/common/mixins/publish";
import PublishButton from "@/common/components/AppPublishButton";
import AthleteResultsTable from "./AthleteResultsTable";
import AthleteForm from "./AthleteForm";
import TableRow from "@/common/components/AppTableRow";
import EditButtons from "@/common/components/AppEditButtons";
import {
  CANCEL_TEXT,
  CONFIRM_TEXT,
  DELETE_ERROR,
  HIDE_NOTICE,
  HIDE_TITLE,
  SAVE_ERROR,
  UNKNOWN_ERROR,
} from "@/common/const/message";
import { Resource } from "@/common/const/common";
import { getImgUrl, getThumbUrl } from "@/common/utils/common";
import { AppRoute } from "@/common/const/route";
import AppimageForm from "@/common/components/AppImageForm";

export default {
  name: "AthleteShow",

  components: {
    AthleteResultsTable,
    PublishButton,
    EditButtons,
    TableRow,
  },

  mixins: [breadcrumbs, publish],

  data() {
    return {
      athlete: {},
      apiResource: Resource.PEOPLES,
      results: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),

    isImages() {
      return this.athlete.images?.length > 0;
    },
    isPublished() {
      return !!this.athlete?.published_at || false;
    },
    isDeleteDisabled() {
      return !this.athlete.createdBy;
    },
  },

  async mounted() {
    const { id } = this.$route.params;
    try {
      this.athlete = await this.$api[Resource.PEOPLES].getOne(id, "find-one");

      if (!this.athlete) {
        throw new Error();
      }

      this.$setBreadcrumbTitle(this.athlete.fullName);
      document.title = this.athlete.fullName;
    } catch (e) {
      // console.log(e);
      await this.$router.push(AppRoute.NOT_FOUND);
    }

    try {
      this.results = await this.$api.fetchData.get(
        `${Resource.PEOPLES}/find-results/${id}`
      );
    } catch (e) {
      console.log(e);
      this.results = null;
    }
  },

  methods: {
    getImgUrl,
    getThumbUrl,

    async handleDownloadAthlete() {
      try {
        this.loading = true;

        const uri =
          process.env.VUE_APP_API_URL +
          `/${Resource.PEOPLES}/download-athlete/${this.athlete.id}`;
        const jwt = this.$jwt.getToken();
        const response = await fetch(uri, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");

        link.href = url;
        link.download = `${this.athlete.lastname}.pdf`;

        link.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        console.log(e);
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.loading = false;
      }
    },

    async handleEdit() {
      this.$buefy.modal.open({
        parent: this,
        component: AthleteForm,
        props: { athlete: this.athlete },
        events: { close: this.handleSave },
        hasModalCard: true,
      });
    },

    async handleEditPhoto() {
      this.$buefy.modal.open({
        parent: this,
        component: AppimageForm,
        props: {
          image: this.athlete.photo,
          fileField: "photo",
          resource: Resource.PEOPLES,
          entityId: this.athlete.id,
        },
        events: {
          close: this.handleImageFormClose,
        },
        hasModalCard: true,
      });
    },

    handleImageFormClose(athlete) {
      if (athlete) {
        this.athlete = athlete;
      }
    },

    async handleSave(athlete) {
      if (athlete) {
        this.athlete = athlete;
        this.$setBreadcrumbTitle(this.athlete.fullName);
      }
    },

    async publish() {
      this.athlete = await this.$publish(this.athlete.id, Resource.PEOPLES);
    },

    async unpublished() {
      this.$buefy.dialog.confirm({
        title: HIDE_TITLE,
        class: "is-size-4",
        message: HIDE_NOTICE,
        cancelText: CANCEL_TEXT,
        confirmText: CONFIRM_TEXT,
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          try {
            this.athlete = await this.$unpublished(
              this.athlete.id,
              Resource.PEOPLES
            );
          } catch (e) {
            this.$notifier.error(UNKNOWN_ERROR);
          }
        },
      });
    },
    async handleRemove() {
      this.$buefy.dialog.confirm({
        title: "Удаление спортсмена",
        message: `"${this.athlete.fullName}" будет удален!!!`,
        cancelText: CANCEL_TEXT,
        confirmText: CONFIRM_TEXT,
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          try {
            await this.$api[Resource.PEOPLES].delete(this.athlete.id);
            this.$notifier.success("Спортсмен удалён");
            await this.$router.push({ name: "AthletesIndex" });
          } catch (e) {
            this.$notifier.error(e.response?.data?.message || DELETE_ERROR);
          }
        },
      });
    },
  },
};
</script>

<style>
.al img {
  max-height: 70px;
}
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}
</style>
